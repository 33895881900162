
import { Routes, Route } from "react-router-dom";
import MainBody from "./components/mainbody/MainBody";
//import Login from "./components/mainbody/Login";
import Missing from "./components/Missing";
import AdminDashboard from "./components/mainbody/AdminDashboard";
//import Register from "./components/mainbody/Register";
import { DataProvider } from "./context/SpeechContext";
import Nav from "./components/Nav";
import Footer from "./components/Footer";


const App = () => {

  return (
    <>
      <DataProvider>
        <main className="min-h-screen flex flex-col">
          <Nav />
          <Routes>
            <Route path="/" element={<MainBody />} />
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/admindashboard" element={<AdminDashboard />} />
            <Route path="*" element={<Missing />} />
          </Routes>
          <Footer />
        </main>
      </DataProvider>
    </>

  );
}

export default App;
