
import { useContext } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import SpeechContext from "../../../context/SpeechContext";

interface TableProps {
    item: any,
    index: any,
}

const AdminTableBody: React.FC<TableProps> = ({ item, index }) => {

        const {setShowDelete, setItemID,setSingleAplication,
             setShowViewModal,
      } = useContext(SpeechContext) || {setShowDelete:()=>{}, setItemID:()=>{}, setSingleAplication:()=>{}, setShowViewModal:()=>{}}


    // const print= () => {

    
    // };

    const viewHandler = (application:any)=>{
        setShowViewModal(true)
        setSingleAplication(application)

    }


    const handleDelete = (id:any) =>{

        setShowDelete(true)
        setItemID(id)

    }


    return <>
       <tr className={`table-row montserrat-regular py-2 cursor-pointer`}>
            <td className=" font-bold py-2">{(index + 1)}</td>
            <td className=" py-2">{item?.full_name}</td>
            <td className=" py-2 max-[342px]:hidden">{item?.organisation}</td>
            <td className=" py-2 max-[630px]:hidden">{item?.position}</td>
            <td className=" py-2 max-[630px]:hidden">{item?.sector}</td>
            {/* <td className=" py-2 cursor-pointer" onClick={() => print()}>Print</td> */}
            <td className=" py-2 flex flex-row "> <IoIosEye fontSize={16} className="mr-4 cursor-pointer" onClick={()=>viewHandler(item)} /> <RiDeleteBin6Line color="red" className="cursor-pointer" onClick={()=>handleDelete(item._id)} /> </td>
        </tr> 
        
    </>
}

export default AdminTableBody;