
import { useContext } from "react";
import SpeechContext from "../../../../context/SpeechContext";

const ViewModal = () => {

    const {singleApplication, showViewModal, setShowViewModal} = useContext(SpeechContext) || {setShowViewModal:()=>{}}

    return <>
{ showViewModal && <>
        <div className="backdrop" onClick={()=>setShowViewModal(false)} ></div>

        <main className=" w-[50%] montserrat-regular max-[568px]:w-[100%] h-[35rem] rounded-xl bg-white px-[1rem] modal-modal2 border border-3 border-[grey] fixed top-[23vh] left-[25%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] overflow-x-hidden overflow-y-scroll ">
            <h1 className="text-center py-2 font-bold">APPLICATION DETAILS</h1>
            <div>
                <label className="font-bold text-[1rem]">Email :</label>
                <p className="text-sm">{singleApplication?.email}</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem]">Full Name :</label>
                <p className="text-sm">{singleApplication?.full_name}</p>
            </div>

            <div className="mt-4">
                <label className="font-bold text-[1rem]">Age :</label>
                <p className="text-sm">{singleApplication?.age }</p>
            </div>

            <div className="mt-4">
                <label className="font-bold text-[1rem]">Gender :</label>
                <p className="text-sm">{singleApplication?.gender}</p>
            </div>

            <div className="mt-4">
                <label className="font-bold text-[1rem]">Phone Number :</label>
                <p className="text-sm">{singleApplication?.phone_number }</p>
            </div>

            <div className="mt-4">
                <label className="font-bold text-[1rem]">Organisation :</label>
                <p className="text-sm">{singleApplication?.organisation}</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem] ">Position :</label>
                <p className="text-sm text-justify">{singleApplication?.position }</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem]">Sector of Ornaisation:</label>
                <p className="text-sm">{singleApplication?.sector }</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem]">Locality :</label>
                <p className="text-sm text-justify">{singleApplication?.locality }</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem]">Locality Type :</label>
                <p className="text-sm text-justify">{singleApplication?.locality_type }</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem]">Do you have any disability? :</label>
                <p className="text-sm text-justify">{singleApplication?.person_with_disability}</p>
            </div>
            <div className="mt-4">
                <label className="font-bold text-[1rem]">If yes, specify? :</label>
                <p className="text-sm text-justify">{singleApplication?.specific_disability}</p>
            </div>
        </main>

        </>}
    </>

}

export default ViewModal;