import { useContext, useState, useEffect } from "react"
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import SpeechContext from "../../../../context/SpeechContext"
import { TailSpin } from 'react-loading-icons';
import { useInView } from 'react-intersection-observer';
import api from '../../../../api/Chats';

const PitchDeck = () => {

    const [showDisability, setShowDisability] = useState<Boolean>(false)

    const [ref, inView] = useInView({
            
    });

    const { state, dispatch, setAppSuccess, setFieldError, setLoadError, loading, setLoading, setCurrentIndex,setGaugewidth } = useContext(SpeechContext) ||
        { dispatch: () => { }, setAppSuccess: () => { }, setFieldError: () => { }, setLoadError: () => { }, setLoading: () => { }, setCurrentIndex: () => { }, setGaugewidth:()=>{} }


    const applicationStates = [
        state?.startup_name,
        state?.operation_duration,
        state?.location,
        state?.team_members,
        state?.founder,
        state?.founder_biography,
        state?.sector_of_impact,
        state?.executive_summary,
        state?.key_problem_solving,
        state?.ai_importance,
        state?.email,
    ]

    const isEmpty = applicationStates.some((item) => item === '')


    const handleUpload = async () => {

        if (isEmpty) {

            setFieldError(true)
            setTimeout(() => {

                setFieldError(false)

            }, 1500)

        } else if (state?.ai_importance === "Yes" && state.pitch_deck === "") {
            setFieldError(true)
            setTimeout(() => {

                setFieldError(false)

            }, 1500)
        }

        else {

            setLoading(true)

            try {



                const applicantPayload = {

                    full_name: state?.startup_name,
                    age: state?.operation_duration,
                    phone_number: state?.location,
                    gender: state?.team_members,
                    organisation: state?.founder,
                    position: state?.founder_biography,
                    sector: state?.sector_of_impact,
                    locality: state?.executive_summary,
                    locality_type: state?.key_problem_solving,
                    person_with_disability: state?.ai_importance,
                    email: state?.email,
                    specific_disability: state?.pitch_deck,

                }


                const response = await api.post('/appform', applicantPayload);

                setLoading(false)
                setAppSuccess(response.data);

                setTimeout(() => {

                    setAppSuccess("")

                    dispatch({ type: "pitch_deck", payload: '' })
                    dispatch({ type: "ai_importance", payload: '' })
                    dispatch({ type: "email", payload: '' })
                    dispatch({ type: "executive_summary", payload: '' })
                    dispatch({ type: "founder", payload: '' })
                    dispatch({ type: "founder_biography", payload: '' })
                    dispatch({ type: "key_problem_solving", payload: '' })
                    dispatch({ type: "location", payload: '' })
                    dispatch({ type: "operation_duration", payload: '' })
                    dispatch({ type: "sector_of_impact", payload: '' })
                    dispatch({ type: "startup_name", payload: '' })
                    dispatch({ type: "team_members", payload: '' })

                    setCurrentIndex(0)
                    setGaugewidth(1)

                }, 1500)

            } catch (err: any) {

                if (err.response) {
                    // console.log(err.response.data)
                    // console.log(err.response.status)
                    // console.log(err.response.headers)
                    setLoading(false)
                    setLoadError('could not submit application, try again')
                    setTimeout(() => {
                        setLoadError("")

                    }, 1500)
                }

                if (err) {
                    setLoading(false)
                    setLoadError('network error')
                    setTimeout(() => {
                        setLoadError("")

                    }, 1500)
                }


            }

        }

    };

    useEffect(() => {
        if (state?.ai_importance === 'Yes') {
            setShowDisability(true)
        } else {
            setShowDisability(false)
        }
    }, [state?.ai_importance])



    return <>
        <main className="w-full flex flex-row ">
            {/* <div className="pl-2 flex flex-row items-center h-[1.5rem] text-[#0D0630] font-bold max-[500px]:hidden"> <p>11</p> <BsArrowRight fontSize="bolder" /></div> */}
            <section className="flex flex-col montserrat-regular pr-[1.5rem] pl-[0.5rem] w-full mt-[4rem] " ref={ref}>
                {showDisability && <>
                    <label className={`font-bold text-[1.2rem] transition-transform duration-1000 transform ${inView ? '-translate-x-0' : 'translate-x-[50%]'}`}>if yes, please specify<sup className="text-[1rem] text-red-500">*</sup></label>
                    <input type="text" className=" border-b-2 border-[#0D0630] mt-[2rem] text-[#0D0630] outline-none" placeholder="enter disability here"
                        value={state?.pitch_deck}
                        onChange={(e) => dispatch({ type: "pitch_deck", payload: e.target.value })}
                    />
                </>}
                <section className="flex place-content-center  w-full relative">
                    {loading && <TailSpin stroke="grey" height={50} strokeWidth={2} fill="grey" className="absolute  left-[45%]" />}
                    <button id="confButton" className={`bg-[#0D0630] text-white w-[10rem] rounded-[0.4rem] mt-4 py-2 text-sm flex flex-row items-center place-content-center`}

                        onClick={handleUpload}
                    >
                        Submit Form <AiOutlineDeliveredProcedure fontSize={20} className="ml-2" />
                    </button>
                </section>
            </section>
        </main>
    </>
}

export default PitchDeck;