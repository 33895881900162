
import { BsPhoneVibrateFill } from 'react-icons/bs';
import { GiWorld } from 'react-icons/gi'; 
import logo from '../images/trans1.png'
import pattern from '../images/YAI_Pattern.png'

const Footer = () => {

    return <>
        <main className="bg-[#0D0630] pb-5 overflow-x-none ">
            <div className="flex justify-between">
                <img src={pattern} width={500} alt="pattern.png" className="h-[3rem] object-cover max-[740px]:hidden" />
                <img src={pattern} width={500} alt="pattern.png" className="h-[3rem] object-cover max-[740px]:hidden" />
                <img src={pattern} width={500} alt="pattern.png" className="h-[3rem] object-cover max-[740px]:hidden" />
                <img src={pattern} width={500} alt="pattern.png" className="h-[3rem] object-cover max-[400px]:hidden" />
                <img src={pattern} width={500} alt="pattern.png" className="h-[3rem] object-cover" />
            </div>
            <section className='flex items-center poppins-semibold pt-5'>
                <section className="container flex justify-between items-center max-[800px]:flex max-[800px]:flex-col text-white  ">
                    <section>
                        <div className='text-white flex flex-row items-center gap-2 pb-4 max-[800px]:place-content-center'>
                            <GiWorld fontSize={20} /> <a href='https://africayouthinnovate.org/home' target='blank'>africayouthinnovate.org</a>
                        </div>
                        <div className='text-white flex flex-row items-center gap-2 pb-4 max-[800px]:place-content-center'>
                            <BsPhoneVibrateFill fontSize={20} /> <p>+233 53 928 6737</p> 
                        </div>
                        <div>

                        </div>
                        <p className="font-bold text-[1rem]  text-[white] max-[800px]:text-center ">
                            Copyright &copy; 2024 </p>
                    </section>
                    <section className='flex content-between items-center max-[800px]:py-3 '>
                        <small className='text-white font-bold'>powered by:</small>
                        <img src={logo} alt="logo.png" className='h-[1.2rem] ml-2 rounded-[50%] ' />
                        <p className='text-white font-bold text-[1rem] pl-1'>Ennovate Blck</p>
                    </section>
                </section>
            </section>
        </main>
    </>
}

export default Footer;