import { useContext } from "react";
import Email from "./homeItems/EmailProgessGauge/Email";
import ProgressGauge from "./homeItems/EmailProgessGauge/ProgressGauge";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import SpeechContext from "../../context/SpeechContext";
import ApplicationSuccessModal from "./homeItems/modals/ApplicationSuccessModal";
import Login from "./Login";

const Home = () => {


  const { currentIndex, questionComponents, prevButtonBgColor,
    nextButtonBgColor, prevSlide, nextSlide, fieldError, loadError } = useContext(SpeechContext) || {}

  //  const prevButton:any = document.getElementById('prevButton')
  //  prevButton.disabled = true

  return <>
    <ApplicationSuccessModal />
    <Login />
    <div className=" flex  place-content-center montserrat-regular font-bold">
      <p className="mt-[7rem] text-center w-[60%] border-b-4 pb-2 border-t-4 pt-2 border-[grey] "><span className="text-[#136DB4] font-extrabold">Young Africa Innovates</span> is aimed at fostering inclusive innovation among diverse youth populations to address social challenges.
        Take the first step by completing the form below, and let's embark on this exciting journey together! 🌟</p>
    </div>
    <main className=" container flex place-content-center mb-[4rem] mt-[1rem] w-full   ">
      <section className="shadow-xlarge rounded-[1.5rem] max-[520px]:w-[100%]  w-[80%] h-[38rem] mt-4 relative ">
        <section className="flex justify-between border-b  shadow-medium items-center px-[2rem] montserrat-regular  py-4" >
          <Email />
          <ProgressGauge />
        </section>
        <section className="rounded-[1.5rem] flex place-content-center items-center  h-[25rem] w-full ">
          {questionComponents[currentIndex as any]}
        </section>
        {fieldError && <div className="flex place-content-center  ">
          <p className="text-sm text-[red] absolute font-bold">All fields must be field</p>
        </div>}
        {loadError && <div className="flex place-content-center  ">
          <p className="text-sm text-[red] absolute font-bold">{loadError}</p>
        </div>}
        <section className="flex justify-between px-[2rem] montserrat-regular mb-2  ">
          <button id="prevButton" className={`${prevButtonBgColor} text-white  w-[6rem] rounded-[0.4rem] py-1  text-sm flex flex-row items-center place-content-center`} onClick={prevSlide} >
            <IoIosArrowRoundBack className="mt-1" fontSize={25} /> previous </button>
          <button id="nextButton" className={`${nextButtonBgColor} text-white w-[6rem] rounded-[0.4rem] py-1 text-sm flex flex-row items-center place-content-center`} onClick={nextSlide}>
            next <IoIosArrowRoundForward className="mt-[0.1rem]" fontSize={25} /> </button>
        </section>
      </section>
    </main>
  </>
}

export default Home;