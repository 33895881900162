
import { useContext } from "react"
import { BsArrowRight } from "react-icons/bs"
import { GiConfirmed } from "react-icons/gi";
import { useInView } from 'react-intersection-observer';
import SpeechContext from "../../../../context/SpeechContext"

const TeamMembers = () => {

    const {nextSlide, state, dispatch, setFieldError} = useContext(SpeechContext) || {dispatch: () => { } , setFieldError:()=>{}, nextSlide:()=>{}}

    const [ref, inView] = useInView({
            
    });

    const confirmHandler = ()=> {
        if(state?.team_members === ""){
            setFieldError(true)
            setTimeout(()=>{
                setFieldError(false)
            }, 1000)
        }else{
            nextSlide()
        }
           
        }

    return <>
        <main className="w-full flex flex-row">
            <div className="pl-2 flex flex-row items-center h-[1.5rem] text-[#0D0630] font-bold"> <p>3</p> <BsArrowRight fontSize="bolder" /></div>
            <section className="flex flex-col montserrat-regular pr-[1.5rem] pl-[0.5rem] w-full" ref={ref}>
                <label  className={`font-bold text-[1.2rem] transition-transform duration-1000 transform ${inView ? '-translate-x-0' : 'translate-x-[50%]'}`}>Gender<sup className="text-[1rem] text-red-500">*</sup></label>
                <input type="text" 
                />
                <select className=" border-b-2 border-[#0D0630] mt-[1rem] text-[#0D0630] outline-none" 
                    value={state?.team_members}
                    onChange={(e)=>dispatch({type:"team_members", payload:e.target.value})} >
                    <option value="">please select your gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
                <button  className={`bg-[#0D0630] text-white w-[6rem] rounded-[0.4rem] mt-4 py-2 text-sm flex flex-row items-center place-content-center`} onClick={confirmHandler}>
                    Confirm <GiConfirmed fontSize={20} className="ml-2" />
                    </button>
            </section>
            
        </main>
    </>
}

export default TeamMembers;