import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import '../src/fonts/Poppins-Medium.ttf';
import '../src/fonts/Poppins-Regular.ttf';
import '../src/fonts/Poppins-SemiBold.ttf';
import '../src/fonts/Montserrat-SemiBold.ttf';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <Router>
    <App />
  </Router>

);


