
import { useContext } from "react";
import { BsArrowRight } from "react-icons/bs"
import { GiConfirmed } from "react-icons/gi";
import { useInView } from 'react-intersection-observer';
import SpeechContext from "../../../../context/SpeechContext";

const Startup = () => {

    const {nextSlide, state, dispatch, setFieldError} = useContext(SpeechContext) || { dispatch: () => { } , setFieldError:()=>{}, nextSlide:()=>{}}

    const [ref, inView] = useInView({
            
    });

    const confirmHandler = ()=> {
        if(state?.startup_name === ""){
            setFieldError(true)
            setTimeout(()=>{
                setFieldError(false)
            }, 1000)
        }else{
            nextSlide()
        }
           
        }

    return <>
        <main className="w-full flex flex-row  " >
            <div className="pl-2 flex flex-row items-center h-[1.5rem] text-[#0D0630] font-bold"> <p>1</p> <BsArrowRight fontSize="bolder" /></div>
            <section className={`flex flex-col montserrat-regular pr-[1.5rem] pl-[0.5rem] w-full`} ref={ref}>
                <label className={`font-bold text-[1.2rem] transition-transform duration-1000 transform ${inView ? '-translate-x-0' : 'translate-x-[50%]'}`}>Full Name<sup className="text-[1rem] text-red-500">*</sup></label>
                <input type="text" className=" border-b-2 border-[#0D0630] mt-[2rem] text-[#0D0630] outline-none" placeholder="please enter your full name"
                    value={state?.startup_name}
                    onChange={(e)=>dispatch({type:"startup_name", payload:e.target.value})}
                 />
                <button  className={`bg-[#0D0630] text-white w-[6rem] rounded-[0.4rem] mt-4 py-2 text-sm flex flex-row items-center place-content-center`} onClick={confirmHandler}>
                    Confirm <GiConfirmed fontSize={20} className="ml-2" />
                    </button>
            </section>
        </main>
    </>
}

export default Startup