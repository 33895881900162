
import { useContext, useState } from "react";
import SpeechContext from "../../../../context/SpeechContext";
import api from '../../../../api/Chats'

const DeleteApplication = () => {

    const {showDelete, setShowDelete, iteID, getAllApplicantions, accountHolderToken} = useContext(SpeechContext) || {setShowDelete:()=>{}, getAllApplicantions:()=>{}}
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [showDeleting, setShowDeleting] = useState<boolean>(false)

    const handleDelete = async()=>{

        try {

            setShowDeleting(true)

            const response = await api.delete(`/deleteAppform/${iteID}`,{
                headers: {
      
                  'Content-Type': 'application/json',
                  'authorization': 'Bearer ' + String(accountHolderToken)
                },
              });


            if(response){
                setShowDeleting(false)
                setShowDelete(false)
                getAllApplicantions()
            }
            
        } catch (err:any) {

            if (err.response) {
                setShowDeleting(false)
                console.log(err.response.status)
                console.log(err.response.headers)
                console.log(err.response.data)
                setErrorMessage('could not delete, try again') 
                setTimeout(()=>{
                    setErrorMessage('')
                    setShowDelete(false)
                    
                }, 1500)
              }

            else if(err){
                setShowDeleting(false)
                setErrorMessage('network error')
                setTimeout(()=>{
                    setErrorMessage('')
                }, 1500)
            }
        }

    }

    return <>
    {showDelete && <>
        <div className="backdrop" onClick={()=>setShowDelete(false)}></div>
        <div className="w-[24rem] max-[568px]:w-[100%]   bg-white  modal-moal-login rounded-[3rem] px-[2rem] py-[2rem]  fixed top-[23vh] left-[40%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto]">
            <p className='border-b-2 border-[grey] text-sm py-2'>Are you sure you want to delete Application ?</p>
            <div className='flex justify-between mt-2 '>
                <button className='text-white bg-[#0D0630] text-sm ml-4 px-2 rounded'  onClick={()=>setShowDelete(false)}>Cancel</button>
                <button className='text-white bg-[red] mr-4 px-2 rounded' onClick={handleDelete} >Delete</button>
                {errorMessage && <p className="text-sm">{errorMessage}</p>}
            </div>
            {showDeleting && <p className="text-sm text-[red] text-center">deleting ....</p>}
        </div>
        </>}
    </>

}

export default DeleteApplication;
