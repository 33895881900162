
import { useContext } from "react";
import AdminTableBody from "./admindashboardItems/AdminTableBody";
import SpeechContext from "../../context/SpeechContext";
import { TailSpin } from 'react-loading-icons';
import DeleteApplication from "./homeItems/modals/DeleteApplicationModal";
import ViewModal from "./homeItems/modals/ViewModal";

const AdminDashboard = () => {

    const { applications, appLoading } = useContext(SpeechContext) || {}

    return <>
        <ViewModal />
        <DeleteApplication />
        <main className=" flex-grow container mt-[4.5rem] montserrat-regular flex place-content-center relative overflow-x-hidden ">
            <div className='table-container' style={{}}>
                <section className='main-table shadow-medium  w-full'>
                    <table className='employeeInfo-table '>
                        <thead className='table-headers border-b-2 border-[#0D0630]'>
                            <tr className="font-bold text-center text-[1rem] max-[1024px]:text-[0.6rem] ">
                                <th className="px-[2rem] py-2">SN</th>
                                <th className="px-[2rem] py-2" >FULL NAME</th>
                                <th className="px-[2rem] py-2 max-[342px]:hidden">ORGANISATION</th>
                                <th className="px-[2rem] py-2 max-[630px]:hidden">POSITION</th>
                                <th className="px-[2rem] py-2 max-[630px]:hidden">SECTOR OF ORGANISATION</th>
                                {/* <th className="px-[2rem] py-2"></th> */}
                                <th className="px-[2rem] py-2"></th>
                            </tr>
                        </thead>
                        {applications ?
                            <tbody className='table-data'>
                                {applications?.map((item, index) => <AdminTableBody item={item} index={index} key={item._id} />)}
                            </tbody>
                            :
                            ''}
                    </table>
                    {!applications && <div className=" text-center flex place-content-center text-sm"><p className="absolute top-[40%] " >
                        No Data Available </p>
                    </div>}
                    {appLoading && <TailSpin stroke="grey" height={50} strokeWidth={2} fill="grey" className="absolute top-[40%] left-[45%]" />}
                </section>
            </div>
        </main>
    </>
}

export default AdminDashboard;