
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosLogIn } from "react-icons/io";
import image from '../images/YAI.png';
import { IoIosLogOut } from "react-icons/io";
import SpeechContext from "../context/SpeechContext";




const Nav = () => {

    const [login, showLogin] = useState(false)

    const { setShowLogin, setApplications } = useContext(SpeechContext) || { setShowLogin: () => { }, setApplications: () => { } }


    const location = useLocation()
    const navigate = useNavigate()


    useEffect(() => {
        if (location.pathname === '/admindashboard') {
            showLogin(false)
        } else {

            showLogin(true)
        }

    }, [location.pathname])

    const logoutHandler = () => {
        localStorage.removeItem('accessToken')
        setApplications([])
        navigate('/')
    }

    return <>
        <main className='shadow-lg pt-2 bg-white fixed w-screen z-50 poppins-semibold'>
            <section className='container flex justify-between items-center'>
                <section className=" flex flex-row items-center">
                    <img src={image} width={200} alt="logo.png" className='h-[4rem] object-cover  ' />
                </section>
                <section className='flex justify-between items-center text-sm  '>
                    {/* <p className='mr-[1rem] border border-[grey] max-[360px]:hidden rounded-[1.5rem] px-[5px] py-[2px] text-[1rem] max-[560px]:text-[0.8rem] max-[460px]:text-[0.5rem] '><span className="text-[orange]"></span> </p> */}
                    {/* <p className='mr-[2rem] cursor-pointer'>Why Us</p>
                    <IoNotifications fontSize={26} className="mr-[2rem]" /> */}
                    {login && <IoIosLogIn fontSize={30} className="cursor-pointer" title="login-Admin only" onClick={() => setShowLogin(true)} />}
                    {!login && <IoIosLogOut fontSize={30} className="cursor-pointer" title="logout" onClick={logoutHandler} />}
                </section>
                {/* <GiHamburgerMenu color="black" fontSize={25} className="min-[360px]:hidden cursor-pointer" /> */}
            </section>
        </main>
    </>
}

export default Nav;