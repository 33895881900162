
import { useContext } from "react"
import { BsArrowRight } from "react-icons/bs"
// import { GiConfirmed } from "react-icons/gi";
import PitchDeck from "./PitchDeck";
import { useInView } from 'react-intersection-observer';
import SpeechContext from "../../../../context/SpeechContext"

const WhyAi = () => {

    const { state, dispatch } = useContext(SpeechContext) || { dispatch: () => { }, setFieldError: () => { }, nextSlide: () => { } }

    const [ref, inView] = useInView({
            
    });

    
    return <>
        <main className="w-full flex flex-row">
            <div className="pl-2 flex flex-row items-center h-[1.5rem] text-[#0D0630] font-bold"> <p>10</p> <BsArrowRight fontSize="bolder" /></div>
            <section className="flex flex-col montserrat-regular pr-[1.5rem] pl-[0.5rem] w-full" ref={ref}>
                <label className={`font-bold text-[1.2rem] max-[630px]:text-[1rem] transition-transform duration-1000 transform ${inView ? '-translate-x-0' : 'translate-x-[50%]'}`}>Do you have any disability?<sup className="text-[1rem] text-red-500">*</sup></label>
                <select className=" border-b-2 border-[#0D0630] mt-[1rem] text-[#0D0630] outline-none"
                    value={state?.ai_importance}
                    onChange={(e) => dispatch({ type: "ai_importance", payload: e.target.value })} >
                    <option value="">please specify</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
                <section>
                    <PitchDeck />
                </section>
                {/* <button className={`bg-[#0D0630] text-white w-[6rem] rounded-[0.4rem] mt-4 py-2 text-sm flex flex-row items-center place-content-center`} onClick={confirmHandler}>
                    Confirm <GiConfirmed fontSize={20} className="ml-2" />
                </button> */}
            </section>
        </main>
    </>
}

export default WhyAi;