
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RiLoginCircleLine } from "react-icons/ri";
import { TailSpin } from 'react-loading-icons';
import api from '../../api/Chats';
import SpeechContext from "../../context/SpeechContext";




const Login = () => {

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>('')


   const {showLogin, setShowLogin,setAccountHolderToken, getAllApplicantions} = useContext(SpeechContext) || {setShowLogin:()=>{}, setApplications:()=>{}, setAccountHolderToken:()=>{}, getAllApplicantions:()=>{}}

    const navigate = useNavigate()

    const loginHandler : React.MouseEventHandler<HTMLButtonElement> = async(e)=>{
            e.preventDefault()
        setLoading(true)

        try {

            const loginPayload = {

                    username : email,
                    password: password
            }

            const response = await api.post('/login', loginPayload);

            setLoading(false)
            
            setAccountHolderToken(response.data.accessToken)
            localStorage.setItem('accessToken', response.data.accessToken)
            setShowLogin(false)
           

            if(response){

                navigate('/admindashboard')
                getAllApplicantions()
            }
            
        } catch (err:any) {

            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.headers)
                setLoading(false)
                setLoginErrorMessage(err.response.data.message)
                setTimeout(()=>{
                    setLoginErrorMessage('')
                },1500)
              }

            else if(err){
                setLoading(false)
                setLoginErrorMessage('network error')
                setTimeout(()=>{
                    setLoginErrorMessage('')
                },1500)
            }
        }
    }


    return <>
    { showLogin && <>
         <div className="backdrop" onClick={()=>setShowLogin(false)}></div>
        <main className=" w-[24rem] max-[568px]:w-[100%]   bg-white  modal-moal-login rounded-[3rem] px-[4rem] py-[2rem]  fixed top-[23vh] left-[35%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] ">
                {/* <section className="form-container px-[4rem] py-[2rem] w-[24rem] rounded-[3rem]"> */}
                    <div className="login-form ">
                        <section className=" text-[1rem] font-bold mb-4 flex items-center place-content-center">
                            <section className=" flex flex-row items-center">
                                
                                <h1 className='text-black ml-2 font-bold text-[1.1rem]'>for Admins only</h1>
                            </section>
                        </section>
                        <hr className="line" color="black" />
                        <div className="form login">
                            <form >
                                <h5 className="text-center text-[1rem] pt-2 font-semibold"><i>Welcome ....</i></h5>
                                <section className=" py-[1rem]">
                                    <div>
                                        <label>
                                            <p className="text-sm pb-1">Username</p>
                                            <input type="text" className="border border-[#0D0630] bg-[transparent] rounded-[5rem] outline-none w-[100%]  text-sm px-3 py-2"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                required
                                                autoComplete="on"
                                            />
                                        </label>
                                        {loading && <TailSpin stroke="grey" height={50} strokeWidth={2} fill="grey" className="absolute left-[45%]" />}
                                        <label>
                                            <p className="text-sm mt-5 pb-1">Password</p>
                                            <input type="password" className="border border-[#0D0630] bg-[transparent] rounded-[2rem] w-[100%] outline-none text-sm px-3 py-2"
                                                value={password} onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>
                                </section>
                                {loginErrorMessage && <p className="text-[0.8rem] text-[orange] text-center font-bold mb-[0.8rem]">{loginErrorMessage}</p>}
                              
                                <div className="flex place-content-center py-2 relative items-center mt-4 text-white" >
                                    <button type="submit" className="bg-[#0D0630] text-center 
                                             text-[1rem] py-1 px-[2rem] rounded-[2rem] w-[100%]"
                                      onClick={loginHandler}
                                    >
                                        Login
                                    </button>
                                    <RiLoginCircleLine fontSize={21} className=" absolute mr-[-10rem]" />
                                </div>
                            </form>
                        </div>
                    </div>
                {/* </section> */}
          
        </main>
        </>}
    </>
}

export default Login;