
import axios from 'axios'

//const httpUrl = process.env.REACT_APP_HTTP_URL

export default axios.create({
    baseURL:"https://ennovate-mastercard-forms-backend.onrender.com"
})



//http://localhost:5000/

//https://ennovate-backend-node.onrender.com

