
import { useContext } from "react";
import SpeechContext from "../../../../context/SpeechContext";

const Email = ()=>{

    const { state, dispatch } = useContext(SpeechContext) || { dispatch: () => { } }

    return <>
       <form className="flex flex-row items-center ">
        <label className="font-bold">Email:</label>
        <input type="text" className="  border border-2 rounded-[0.8rem] px-2 py-[0.1rem] w-[100%] md:w-[20rem] border-[#0D0630]  text-[#0D0630] ml-2 outline-none" placeholder="add email here"
             value={state?.email}
             onChange={(e)=>dispatch({type:"email", payload:e.target.value})}
         />
       </form>
    </>
}

export default Email;