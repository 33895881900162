import { useContext } from "react"
import { BsArrowRight } from "react-icons/bs"
import { GiConfirmed } from "react-icons/gi";
import sectors from '../../../../data/sectors.json'
import { useInView } from 'react-intersection-observer';
import SpeechContext from "../../../../context/SpeechContext"



const SectorImpact = () => {

    const { nextSlide, state, dispatch,setFieldError } = useContext(SpeechContext) || { dispatch: () => { } , setFieldError:()=>{}, nextSlide:()=>{} }

    const [ref, inView] = useInView({
            
    });

    const confirmHandler = ()=> {
        if(state?.sector_of_impact === ""){
            setFieldError(true)
            setTimeout(()=>{
                setFieldError(false)
            }, 1000)
        }else{
            nextSlide()
        }
           
        }

    return <>
        <main className="w-full flex flex-row">
            <div className="pl-2 flex flex-row items-center h-[1.5rem] text-[#0D0630] font-bold"> <p>7</p> <BsArrowRight fontSize="bolder" /></div>
            <section className="flex flex-col montserrat-regular pr-[1.5rem] pl-[0.5rem] w-full" ref={ref}>
                <label className={`font-bold text-[1.2rem] transition-transform duration-1000 transform ${inView ? '-translate-x-0' : 'translate-x-[50%]'}`}>Sector<sup className="text-[1rem] text-red-500">*</sup></label>
                <select className="border-b-2 border-[#0D0630] mt-[2rem] text-[#0D0630] outline-none cursor-pointer"
                    value={state?.sector_of_impact}
                    onChange={(e) => dispatch({ type: "sector_of_impact", payload: e.target.value })}
                >
                    <option value="">please specify the sector you work in</option>
                    {sectors.map((sector) => <option key={sector} value={sector}>{sector}</option>)}
                </select>
                <button className={`bg-[#0D0630] text-white w-[6rem] rounded-[0.4rem] mt-4 py-2 text-sm flex flex-row items-center place-content-center`} onClick={confirmHandler}>
                    Confirm <GiConfirmed fontSize={20} className="ml-2" />
                </button>
            </section>
        </main>
    </>
}

export default SectorImpact;
