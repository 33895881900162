

import React, { createContext, ReactNode, useState, useReducer, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Startup from "../components/mainbody/homeItems/questionComps/Startup";
import OperationLength from "../components/mainbody/homeItems/questionComps/Operationlength";
import TeamMembers from "../components/mainbody/homeItems/questionComps/TeamMembers";
import Location from "../components/mainbody/homeItems/questionComps/Location";
import Founder from "../components/mainbody/homeItems/questionComps/Founder";
import FounderBiography from "../components/mainbody/homeItems/questionComps/FounderBiography";
import SectorImpact from "../components/mainbody/homeItems/questionComps/SectorImpact";
import ExecutiveSummary from "../components/mainbody/homeItems/questionComps/ExecutiveSummary";
import KeyProblem from "../components/mainbody/homeItems/questionComps/KeyProblem";
import WhyAi from "../components/mainbody/homeItems/questionComps/WhyAi";
import PitchDeck from "../components/mainbody/homeItems/questionComps/PitchDeck";
import api from '../api/Chats'


interface signState {
  startup_name: string,
  operation_duration: string,
  team_members: string,
  location: string,
  founder: string,
  founder_biography: string,
  sector_of_impact: string,
  executive_summary: string,
  key_problem_solving: string,
  ai_importance: string,
  email: string,
  pitch_deck: any,
}


type signActions =
  { type: 'startup_name'; payload: string }
  | { type: 'operation_duration'; payload: string }
  | { type: 'team_members'; payload: string }
  | { type: 'location'; payload: string }
  | { type: 'founder'; payload: string }
  | { type: 'founder_biography'; payload: string }
  | { type: 'sector_of_impact'; payload: string }
  | { type: 'executive_summary'; payload: string }
  | { type: 'key_problem_solving'; payload: string }
  | { type: 'ai_importance'; payload: string }
  | { type: 'pitch_deck'; payload: any }
  | { type: 'email'; payload: string }



const ApplicationState: signState = {
  startup_name: "",
  operation_duration: "",
  team_members: "",
  location: "",
  founder: "",
  founder_biography: "",
  sector_of_impact: "",
  executive_summary: "",
  key_problem_solving: "",
  ai_importance: "",
  email: "",
  pitch_deck: ""
};


const reducer = (state: signState, action: signActions) => {

  switch (action.type) {

    case 'startup_name': return { ...state, startup_name: action.payload }
    case 'operation_duration': return { ...state, operation_duration: action.payload }
    case 'team_members': return { ...state, team_members: action.payload }
    case 'location': return { ...state, location: action.payload }
    case 'founder': return { ...state, founder: action.payload }
    case 'founder_biography': return { ...state, founder_biography: action.payload }
    case 'sector_of_impact': return { ...state, sector_of_impact: action.payload }
    case 'executive_summary': return { ...state, executive_summary: action.payload }
    case 'key_problem_solving': return { ...state, key_problem_solving: action.payload }
    case 'ai_importance': return { ...state, ai_importance: action.payload }
    case 'email': return { ...state, email: action.payload }
    case 'pitch_deck': return { ...state, pitch_deck: action.payload }

    default: throw new Error()
  }
}


interface speechContextProps {

  state: signState;
  dispatch: React.Dispatch<signActions>;
  accountHolderToken: string;
  setAccountHolderToken: React.Dispatch<React.SetStateAction<string>>
  prevSlide: () => void,
  nextSlide: () => void,
  prevButtonBgColor: string;
  setPrevButtonBgColor: React.Dispatch<React.SetStateAction<string>>
  nextButtonBgColor: string,
  setNextButtonBgColor: React.Dispatch<React.SetStateAction<string>>,
  questionComponents: any;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>,
  gaugeWidth: number;
  setGaugewidth: React.Dispatch<React.SetStateAction<number>>,
  progress: number,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
  fieldError: boolean,
  setFieldError: React.Dispatch<React.SetStateAction<boolean>>
  appSuccess: string,
  setAppSuccess: React.Dispatch<React.SetStateAction<string>>,
  loadError: string;
  setLoadError: React.Dispatch<React.SetStateAction<string>>,
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  applications: any[],
  setApplications: React.Dispatch<React.SetStateAction<any[]>>
  getAllApplicantions: () => void
  showLogin: boolean
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
  appLoading: boolean
  setAppLoading: React.Dispatch<React.SetStateAction<boolean>>
  showDelete: boolean
  setShowDelete: React.Dispatch<React.SetStateAction<boolean>>
  iteID: string
  setItemID: React.Dispatch<React.SetStateAction<string>>
  singleApplication: any
  setSingleAplication: React.Dispatch<React.SetStateAction<any>>
  showViewModal: boolean
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>
}


const SpeechContext = createContext<speechContextProps | undefined>(undefined)

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {


  const [state, dispatch] = useReducer(reducer, ApplicationState)
  const [accountHolderToken, setAccountHolderToken] = useState<any>('')
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [prevButtonBgColor, setPrevButtonBgColor] = useState<string>('bg-[#0D0630]');
  const [nextButtonBgColor, setNextButtonBgColor] = useState<string>('bg-[#0D0630]');
  const [gaugeWidth, setGaugewidth] = useState<number>(1)
  const [progress, setProgress] = useState<number>(0)
  const [fieldError, setFieldError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadError, setLoadError] = useState<string>('')
  const [appSuccess, setAppSuccess] = useState<string>('')
  const [applications, setApplications] = useState<any[]>([])
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [appLoading, setAppLoading] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [iteID, setItemID] = useState<string>('')
  const [singleApplication, setSingleAplication] = useState<any>()
  const [showViewModal, setShowViewModal] = useState<boolean>(false)


  const location = useLocation()
  const navigate = useNavigate()


  const questionComponents = [<Startup />, <OperationLength />, <TeamMembers />, <Location />, <Founder />, <FounderBiography />, <SectorImpact />, <ExecutiveSummary />, <KeyProblem />, <WhyAi />]

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? questionComponents.length - 1 : (currentIndex as any) - 1;
    setCurrentIndex(newIndex);
    setGaugewidth(prevWidth => prevWidth - 1);

  };

  const nextSlide = () => {
    const newIndex = currentIndex === questionComponents.length - 1 ? 0 : (currentIndex as any) + 1;
    setCurrentIndex(newIndex);
    setGaugewidth(prevWidth => prevWidth + 1);
  };


  useEffect(() => {
    if (currentIndex === 0) {
      if (location.pathname === "/") {
        const prevButton: any = document.getElementById('prevButton')

        prevButton.disabled = true
        setPrevButtonBgColor('bg-[grey]')
      }

    } else {
      const prevButton: any = document.getElementById('prevButton')

      prevButton.disabled = false
      setPrevButtonBgColor('bg-[#0D0630]')

    }

    if (currentIndex === questionComponents.length - 1) {
      const nxtButton: any = document.getElementById('nextButton')
      if (nxtButton) {
        nxtButton.disabled = true
        setNextButtonBgColor('bg-[grey]')
      }

    } else {
      const nxtButton: any = document.getElementById('nextButton')
      if (nxtButton) {
        nxtButton.disabled = false
        setNextButtonBgColor('bg-[#0D0630]')
      }
    }
  }, [currentIndex, questionComponents.length, location.pathname])



  //this increases the gauge

  useEffect(() => {

    setProgress(Math.floor(((gaugeWidth as any) / (questionComponents.length)) * 100));

  }, [state.startup_name, gaugeWidth, questionComponents.length, state.operation_duration]);


  //this is the function that fetches all applications

  const getAllApplicantions = useCallback(async () => {

    setAppLoading(true)

    try {

      const response = await api.get('/getAllAppform', {
        headers: {

          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + String(accountHolderToken)
        },
      });


      if (response.status === 200) {

        setApplications(response.data)

        setAppLoading(false)

      }

    } catch (err: any) {

      if (err.response) {
        console.log(err.response.data)
        console.log(err.response.status)
        console.log(err.response.headers)
        setAppLoading(false)
      }

      else if (err) {
        console.log('network error')
        setAppLoading(false)
      }
    }
  }, [accountHolderToken, setApplications, setAppLoading])

  useEffect(() => {

    if (localStorage.getItem('accessToken')) {

      const token = localStorage.getItem('accessToken')
      setAccountHolderToken(token)
      getAllApplicantions()
    } else {
      navigate('/')
    }

  }, [navigate, getAllApplicantions])



  return <>
    <SpeechContext.Provider value={{
      accountHolderToken, setAccountHolderToken, prevButtonBgColor, setPrevButtonBgColor, nextButtonBgColor, setNextButtonBgColor,
      questionComponents, currentIndex, setCurrentIndex, state, dispatch, gaugeWidth, setGaugewidth, progress, setProgress,
      fieldError, setFieldError, appSuccess, setAppSuccess, loadError, setLoadError, loading, setLoading, applications, setApplications,
      showLogin, setShowLogin, appLoading, setAppLoading, showDelete, setShowDelete, iteID, setItemID, singleApplication, setSingleAplication,
      showViewModal, setShowViewModal,

      prevSlide, nextSlide, getAllApplicantions,
    }}>
      {children}
    </SpeechContext.Provider>
  </>
}

export default SpeechContext;

