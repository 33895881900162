
import Home from "./Home";




const MainBody = () => {



    return <>
        <main className="min-h-screen">
            <Home />
        </main>
    </>
}

export default MainBody;