
import { useContext } from "react";
import SpeechContext from "../../../../context/SpeechContext";

const ProgressGauge = ()=>{

    const {progress} = useContext(SpeechContext) || {}

    return <>
         <div className="max-[415px]:hidden">
                    <div className='outer'
                        style={{ backgroundImage: `conic-gradient( orange ${progress}%, #0D0630 0%)`, marginLeft: '30px' }}>
                        <div className='inner'>
                            <div id="number" className="text-[#0D0630] font-bold">{progress as any}%</div>
                        </div>
                    </div>
                </div>
    </>
}

export default ProgressGauge;